import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useState } from "react";
import { isEmailValid } from "../../utils/middleware";
import { getFunctions, httpsCallable } from "firebase/functions";
import { sendEmailAPI } from "../../actions";
// import axios from "axios";
// import firebase from 'firebase/compat/app';

const Footer = (props) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  // ERRORS
  const [emailError, setEmailError] = useState("");

  const validateEmail = (value) => {
    setEmail(value);
    let emailRes = isEmailValid(value);
    setEmailError(emailRes[1] ? emailRes[1] : "");
  };

  const handleContact = async (e) => {
    e.preventDefault();

    if (e.target !== e.currentTarget) {
      return;
    }

    // Create a new FormData object to send data including the file
    const payload = new FormData();
    var subject = "Message from CleanForce"
    payload.append('username', username);
    payload.append('fromEmail', email);
    payload.append('toEmail', "info@cleanforce.tech");  // bernard.v.ayettey@gmail.com
    payload.append('message', message);
    payload.append('subject', subject); 

    props.sendEmail(payload);

    // Send the data to the backend server using axios (or any other HTTP client library)
    // axios
    //   .post("http://localhost:5000/send-email", payload)
    //   .then((response) => {
    //     console.log("Email sent successfully!");
    //   })
    //   .catch((error) => {
    //     console.error("Error sending email:", error);
    //   });

    // Reset form fields after sending the email
    setUsername("");
    setEmail("");
    setMessage("");
  };

  return (
    <FooterSection id="contact">
      <Layout>
        <LeftSide>
          <h2>Important links</h2>
          <p>
            <a href="/">Home</a>
          </p>
          <p>
            <a href="/about">About</a>
          </p>
          <p>
            <a href="/home/services">Services</a>
          </p>
          <p>
            <a href="/home/reviews">Reviews</a>
          </p>
        </LeftSide>

        <Main>
          <h2>Location</h2>
          <p>
            We love our customers, so feel free to visit us at WAAS in Lagos.
          </p>
          <p>WAAS, Lagos</p>
          <p>Call Us: +233559553056</p>
          <p>
            or <a href="mailto:info@cleanforce.tech">Leave a mail</a>
          </p>
        </Main>

        <RightSide>
          <h2>Contact Us</h2>
          <Form>
            <form>
              <div className="inputbox-wrap">
                <div className="inputbox">
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required="required"
                  />
                  <span>Name</span>
                </div>
              </div>

              <div className="inputbox-wrap">
                <div className="inputbox">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => validateEmail(e.target.value)}
                    required="required"
                  />
                  <span>Email</span>
                </div>
                {emailError && <p>{emailError}</p>}
              </div>

              <div className="inputbox-wrap">
                <textarea
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Message"
                  required="required"
                />
              </div>

              <div className="inputbox">
                <input
                  type="button"
                  disabled={!(username && email) ? true : false}
                  onClick={(event) => handleContact(event)}
                  value="Send"
                />
              </div>
            </form>
          </Form>
        </RightSide>
      </Layout>

      <Copyright>
        <p className="text-center">
          Copyright © 2023 
          <a href="/" className="text-center">
            CLEANFORCE
          </a>
          . All rights reserved.
        </p>
        <p className="text-center">
          Designed by 
          <a href="#" className="text-center">
            Koffi Cobbin
          </a>
        </p>
      </Copyright>
    </FooterSection>
  );
};

const FooterSection = styled.div`
  margin-top: 10px;
  /* background-color: #3cb043; */
  background: linear-gradient(45deg, green, #3f704d);
  /* #3f704d; */
  color: white;
  padding: 10px;
  margin-bottom: 0;
  @media (max-width: 768px) {
  }
`;

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  h2 {
    color: #fff;
  }
  p {
    font-size: 12px;
    padding: 1px;
    line-height: 1.5;
    a {
      text-decoration: none;
      color: white;
    }
  }
  @media (min-width: 768px) {
    width: 80%;
    margin: 20px auto;
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const LeftSide = styled.div`
  width: 30%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Main = styled.div`
  width: 30%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RightSide = styled.div`
  width: 30%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Form = styled.div`
  /* background: #fff; */
  border-radius: 10px;
  margin: 20px;
  & .inputbox-wrap {
    & p {
      text-align: left;
      padding-left: 10px;
      color: red;
    }
    margin-bottom: 30px;
  }
  & .inputbox {
    height: 50px;
    padding: 0;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
  }
  & input {
    position: relative;
    padding: 11px 5px;
    border-radius: 10px;
    font-size: 1.2em;
    border: 2px solid #fff;
    outline: none;
    display: block;
    width: 100%;
    &:focus ~ span,
    &:valid ~ span {
      transform: translateX(-13px) translateY(-35px);
      font-size: 1em;
    }
  }
  & input[type="file"] {
    border: none;
    padding-top: 0;
  }

  & textarea {
    width: 100%;
    border-radius: 10px;
    padding: 11px 5px;
  }

  & span {
    position: absolute;
    top: 14px;
    left: 20px;
    font-size: 1em;
    transition: 0.6s;
    font-family: sans-serif;
    background-color: #3f704d;
    padding: 2px 5px;
    border-radius: 7px;
  }

  & [type="button"] {
    width: 100%;
    background: #3f704d;
    color: #fff;
    border: 1px solid #fff;
    &:hover {
      background: linear-gradient(45deg, greenyellow, #3f704d);
    }
  }
  @media (max-width: 768px) {
    padding: 20px;
    & h1 {
      font-size: 1.5em;
    }
    & span {
      top: 16px;
      font-size: 13px;
    }
  }
`;

const Copyright = styled.div`
  margin-top: 30px;
  p {
    font-size: 13px;
    margin: 10px auto;
    line-height: 1.5;
    .text-center {
      color: white;
      text-decoration: none;
      margin: 1 2px;
      padding: 5px;
      font-weight: 600;
    }
  }
`;


const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => ({
  sendEmail: (payload) => dispatch(sendEmailAPI(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
