import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "styled-components";

import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Layout/Nav/Navbar";
import SideNav from "./components/Layout/Nav/SideNav";
import Home from "./components/Home/Home";
import Signup from "./components/Auth/Signup";
import Login from "./components/Auth/Login";
import Loading from "./components/Shared/Loading";
import Footer from "./components/Layout/Footer";
import About from "./components/Home/About";
import Services from "./components/Services/Services";
import Logout from "./components/Auth/Logout";
import "./App.css";

function App(props) {
  const [scrollTop, setScrollTop] = useState(0);
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openSideNav = () => {
    setOpenNav(true);
  };

  const closeSideNav = () => {
    setOpenNav(false);
  };

  const scrollUp = () => {
    const element = document.getElementById("app");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="App" id="app">
      <Router>
        <ScrollToTop />
        <Navbar sidenav={openSideNav}/>
        <Routes>
          {["/", "/home", "/home/:secId"].map((path, index) => (
            <Route
              exact
              path={path}
              key={index}
              element={[
                <Home />,
              ]}
            />
            ))}

          <Route
            path="/about"
            element={[
              <About />,
            ]}
          />

          {["/services/:serviceId"].map((path, index) => (
            <Route
              exact
              path={path}
              key={index}
              element={[
                <Services />,
              ]}
            />
            ))}


          <Route
            path="/login"
            element={[
              <Login />,
            ]}
          />

          <Route
            path="/signup"
            element={[
              <Signup />,
            ]}
          />

          <Route
            path="/logout"
            element={[
              <Logout />,
            ]}
          />
        </Routes>
        {
          openNav && (
            // <Modal close={closeSideNav}>
            <SideNav width={openNav ? "70%" : "0"} close={closeSideNav} />
          )
          // </Modal>
        }
      </Router>

      {props.loading && <Loading />}

      <Footer />

      <ScrollToTopButton
        style={{ display: scrollTop > 100 ? "block" : "none" }}
        onClick={scrollUp}
      >
        <img src="/images/icons/up-arrow-w.svg" alt="Up" />
      </ScrollToTopButton>
    </div>
  );
}

const ScrollToTopButton = styled.button`
  position: sticky;
  bottom: 20px;
  float: right;
  height: 40px;
  width: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: #3F704D;
  z-index: 50;
  img {
    height: 20px;
    width: 20px;
  }
`;

const mapStateToProps = (state) => {
  return {
    loading: state.appState.loading,
    loading_message: state.appState.loading_message,
  };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
