import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";


const Technology = (props) => {

  return (
    <>
        <Section id="services">
          <Title>Products</Title>
          <ServiceList>
            <Service>
                <BackgroundImage
                  style={{ backgroundImage: `url('/images/1.jpg')` }}
                />
              <ServiceInfo>
                <ServiceTitle> Rig wash </ServiceTitle>

                <Content>
                  We Manufacture eco-friendly degreases / Rig wash, & anti rust
                  agents as well as represent global brands across Africa
                </Content>
              </ServiceInfo>
            </Service>

            <Service>
                <BackgroundImage
                  style={{ backgroundImage: `url('/images/2.jpg')` }}
                />
              <ServiceInfo>
                <ServiceTitle> Eco-friendly degrease </ServiceTitle>

                <Content>
                  Providing complete customer satisfaction by using engineered
                  innovation to solve various customer challenges.
                </Content>
              </ServiceInfo>
            </Service>

            <Service>
                <BackgroundImage
                  style={{ backgroundImage: `url('/images/3.jpg')` }}
                />
              <ServiceInfo>
                <ServiceTitle> Innovation Technology </ServiceTitle>

                <Content>
                  We provide innovative industrial solutions and services with a
                  focus on engineering, solution development and sustainability.
                  Utilzing Green Tech
                </Content>
              </ServiceInfo>
            </Service>
          </ServiceList>
        </Section>
    </>
  );
};

const Section = styled.div`
  position: relative;
  width: 90%;
  overflow-x: hidden;
  margin: 10px auto;
  p{
    text-align: left;
    line-height: 1.5;
  }
`;

const Title = styled.h1`
  color: green;
  margin: 40px 0;
  text-align: center;
`;

const ServiceList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;
const Service = styled.div`
  width: 30%;
  border-radius: 30px 30px 0 0;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const BackgroundImage = styled.div`
  width: 100%;
  border-radius: 30px 30px 0 0;
  background-color: #333;
  background-position: center;
  background-size: cover;
  height: 200px;
`;

const ServiceInfo = styled.div`
    color: #36454F;
    position: relative;
    flex-grow: 1;
    padding: 20px;    
`;

const Content = styled.p``;

const ServiceTitle = styled.h3`
  margin-top: 1px;
  margin-bottom: 5px;
  font-weight: 600;
  color: #3F704D;
`;
export default Technology;
