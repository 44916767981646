import { combineReducers } from "redux";
import userReducer from "./userReducer";
import appReducer from "./appReducer";

const rootReducer = combineReducers({
    userState: userReducer,
    appState: appReducer,
});

export default rootReducer;
