export const imageSliders = [
    {
        url: '/images/1.jpg',
        title: 'Chase away rust',
        description: "With our eco-friendly degreases"
    },
    {
        url: '/images/2.jpg',
        title: "Rig wash made easy",
        description: "Get eco-friendly rig wash"
    },
    {
        url: '/images/3.jpg',
        title: 'Green Tech',
        description: "innovative industrial solutions"
    }
]

export const locations = [
    "Greater Accra",
    "Cape Coast",
    "Kumasi",
    "Tamale",
    "Koforidua"
]

export const dateFilters = [
    "Today",
    "This week",
    "This month",
    "Next month",
    "Koforidua"
]

export const eventsData = [
    {
        id: 1,
        imageUrl: '/images/7.png',
        title: 'Beauty Spa Training',
        date: "Mon. 11th Apr. 2023",
        startTime: "2:00PM",
        endTime: "HH PM/AM",
        status: "live",
        type: "free",
        venue: "live",
        address: "Ghana, Accra, Osu",
        attendees: "902",
        slots: "88",
        category: "popular",
        description: "How to Create React Full Screen Automatic Background Image Slider",
        images: ["/images/1.jpg", "/images/2.jpg", "/images/3.jpg", "/images/4.png", "/images/7.png"]
    },
    {
        id: 2,
        imageUrl: '/images/6.jpg',
        title: 'The Rock Party',
        date: "WD MM DD YYY",
        startTime: "HH PM/AM",
        endTime: "HH PM/AM",
        status: "upcoming",
        type: "ticketed",
        venue: "live",
        address: "Vienna City Night Club, Accra",
        attendees: "388",
        tickets: "602",
        category: "popular",
        description: "In this video I will show you how to create full screen automatic background image slider in React js",
        images: ["/images/1.jpg", "/images/2.jpg", "/images/3.jpg", "/images/4.png", "/images/7.png"]
    },
    {
        id: 3,
        imageUrl: '/images/11.jpg',
        title: 'Vision Live Music',
        date: "WD MM DD YYY",
        startTime: "HH PM/AM",
        endTime: "HH PM/AM",
        status: "postponed",
        type: "ticketed",
        venue: "live",
        address: "Labadi Beach Hotel, Labadi",
        attendees: "1K",
        tickets: "0",
        category: "popular",
        description: "Photo by Mateusz Sałaciak from Pexels",
        images: ["/images/1.jpg", "/images/2.jpg", "/images/3.jpg", "/images/4.png", "/images/7.png"]
    },
    {
        id: 4,
        imageUrl: '/images/5.png',
        title: 'Creative Business Training',
        date: "WD MM DD YYY",
        startTime: "HH PM/AM",
        endTime: "HH PM/AM",
        status: "canceled",
        type: "free",
        venue: "online",
        address: "www.creativegensint.com",
        attendees: "1.2K",
        slots: "0",
        category: "popular",
        description: "Photo by Mateusz Sałaciak from Pexels",
        images: ["/images/1.jpg", "/images/2.jpg", "/images/3.jpg", "/images/4.png", "/images/7.png"]
    },
    {
        id: 5,
        imageUrl: '/images/13.jpg',
        title: 'Brazil Street Carnival',
        date: "WD MM DD YYY",
        startTime: "HH PM/AM",
        endTime: "HH PM/AM",
        status: "live",
        type: "free",
        venue: "live",
        address: "Osu Oxford Street, OSu",
        attendees: "902",
        slots: "88",
        category: "for you",
        description: "How to Create React Full Screen Automatic Background Image Slider",
        images: ["/images/1.jpg", "/images/2.jpg", "/images/3.jpg", "/images/4.png", "/images/7.png"]
    },
    {
        id: 6,
        imageUrl: '/images/1.jpg',
        title: 'Africa Music Festival',
        date: "WD MM DD YYY",
        startTime: "HH PM/AM",
        endTime: "HH PM/AM",
        status: "upcoming",
        type: "ticketed",
        venue: "live",
        address: "Independence Square, Osu",
        attendees: "388",
        tickets: "602",
        category: "for you",
        description: "In this video I will show you how to create full screen automatic background image slider in React js",
        images: ["/images/1.jpg", "/images/2.jpg", "/images/3.jpg", "/images/4.png", "/images/7.png"]
    },
    {
        id: 7,
        imageUrl: '/images/3.jpg',
        title: 'Afro Summer Party',
        date: "WD MM DD YYY",
        startTime: "HH PM/AM",
        endTime: "HH PM/AM",
        status: "postponed",
        type: "ticketed",
        venue: "live",
        address: "Bridge View Hotel, Akosombo",
        attendees: "1K",
        tickets: "0",
        category: "trending",
        description: "Photo by Mateusz Sałaciak from Pexels",
        images: ["/images/1.jpg", "/images/2.jpg", "/images/3.jpg", "/images/4.png", "/images/7.png"]
    },
    {
        id: 8,
        imageUrl: '/images/10.jpg',
        title: 'Jazz Music Opening',
        date: "WD MM DD YYY",
        startTime: "HH PM/AM",
        endTime: "HH PM/AM",
        status: "canceled",
        type: "ticketed",
        venue: "live",
        address: "La Gallete, Spintex Road",
        attendees: "1.2K",
        slots: "0",
        category: "for you",
        description: "Photo by Mateusz Sałaciak from Pexels",
        images: ["/images/1.jpg", "/images/2.jpg", "/images/3.jpg", "/images/4.jpg", "/images/7.png"]
    },
    {
        id: 9,
        imageUrl: '/images/4.png',
        title: 'Jazz Music Opening',
        date: "WD MM DD YYY",
        startTime: "HH PM/AM",
        endTime: "HH PM/AM",
        status: "canceled",
        type: "ticketed",
        venue: "live",
        address: "La Gallete, Spintex Road",
        attendees: "1.2K",
        slots: "0",
        category: "trending",
        description: "Photo by Mateusz Sałaciak from Pexels",
        images: ["/images/1.jpg", "/images/2.jpg", "/images/3.jpg", "/images/4.jpg", "/images/7.png"]
    }
]

export const categoriesData = [
    "For you",
    "Today",
    "Free",
    "This weekend",
    "Fashion",
    "Party",
    "Sports",
    "Music",
    "Game",
    "Webinar",
    "Conference",
    "Holiday",
    "Movie",
    "Concert",
    "Startup",
    "This month",
    "Easter",
    "Food",
    "Race",
    "Campus",
    "Church",
    "Launch",
    "Movie"
]

export const DUMMY_TICKETS = [
    {
      id: 1,
      type: 'Reg.',
      name: 'Live in Accra',
      location: 'Seats in regular stands',
      price: 22.99,
    },
    {
      id: 2,
      type: 'VIP',
      name: 'Live in Accra',
      location: '3rd row seats',
      price: 30.5,
    },
    {
      id: 3,
      type: 'VVIP',
      name: 'Live in Accra',
      location: 'Second row seats',
      price: 40.99,
    },
    {
      id: 4,
      type: 'Exec.',
      name: 'Live in Accra',
      location: 'First row seats',
      price: 99.99,
    },
  ];